import React, { FC, memo } from 'react';
import { useGetTreeTypeDimensionDataQuery } from '../../../../data/api/RefDataApi';
import { Dimension, ScopeType } from '../../../../data/model/DataModels';
import RegionTree from '../../../RegionTree/RegionTree';
import { buildTreeFromSelectedValuesAndAddIcons } from '../../ScopeHelper';
import styles from './ShowTreeTypeDimension.module.scss';

export interface ShowTreeTypeDimensionProps {
  dimension: Dimension;
  type: ScopeType;
}

const ShowTreeTypeDimension: FC<ShowTreeTypeDimensionProps> = ({ dimension, type }) => {

  const { data: allDimensionValues, isLoading: isLoadingAllDimensionValues } = useGetTreeTypeDimensionDataQuery(dimension.type);
  const processedNodes = buildTreeFromSelectedValuesAndAddIcons(allDimensionValues || [], dimension.value, type);

  return (
    <div className={styles.ShowTreeTypeDimension} data-testid="ShowTreeTypeDimension">
      {
        processedNodes.length > 0 ?
          <div className={styles.regionTreeContent}>
            <RegionTree nodes={processedNodes} selectedValuesOnTree={[]} editMode={false} />
          </div>
          :
          <div className={styles.listItem}>
            <span
              className={type == "inclusion" ? `pi pi-check-circle ${styles.checkIcon}` : `pi pi-ban ${styles.banIcon}`} />
            <span>-</span>
          </div>

      }
    </div>
  );
}

export default memo(ShowTreeTypeDimension);
